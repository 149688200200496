import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Personal from './Personal';
import DCIPanel from './DCIList';
import Root from './Layout';
import { ConfigProvider, theme, message,Steps,Modal,Flex, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {ExclamationCircleOutlined} from '@ant-design/icons';

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const [current, setCurrent] = useState({step: 0,msg:null,status:'process'});
  const [steps, setSteps] = useState([]);
  const updateCurrent = ({step,msg,status='process'}) => {
    if(step!==undefined){
      setCurrent({step,msg,status});
    }else{
      setCurrent({...current,msg,status});
    }
  }
  let {step,msg,status} = current;
  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
    if(searchParams.has('refcode')){
      localStorage.setItem('refcode',searchParams.get('refcode'));
    }
  },[])
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#ff4895',
        primaryShadow: 'none',
        colorBgContainer: "#1d1a27",
        defaultShadow: 'none',
      },
      algorithm: [theme.darkAlgorithm],
      components: {
        Form: {
          itemMarginBottom: 16
        },
        Table:{
          rowSelectedBg: '#094ca8',
          rowSelectedHoverBg: '#1667dc'
        },
        Button: {
          colorPrimary: 'linear-gradient(127.51deg, rgb(252, 73, 130) 1.151%,rgb(81, 86, 254) 99.939%)',
          lineWidth: 0,
          borderRadius: '100px'
        },
      },
    }}
    
  >
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Root messageApi={messageApi} setCurrentStep={updateCurrent} setSteps={setSteps}/>} >
          <Route index element={<DCIPanel messageApi={messageApi} setCurrentStep={updateCurrent} setSteps={setSteps} />} />
          <Route path='home' element={<Personal messageApi={messageApi} setCurrentStep={updateCurrent} setSteps={setSteps}/>} />
          <Route path="dci" element={<DCIPanel messageApi={messageApi} setCurrentStep={updateCurrent} setSteps={setSteps}/>} />
        </Route>
      </Routes>
    {contextHolder}
    <Modal open={steps.length>0} centered width={500} onCancel={()=>setSteps([])} footer={null} zIndex={1000}>
      <Flex vertical align='center'>
      <Steps current={step} items={steps} direction="vertical" status={status}/>
      {
        ['error','finish'].includes(status)&&<Typography.Text type='error' className={`mmsg ${status}`}>
            <ExclamationCircleOutlined /> {msg
          }</Typography.Text>
      }
      </Flex>
    </Modal>
    </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
