import { BS,BSHolder } from "./bs";
import { createContext } from "react";
import dayjs from "dayjs";
export const ONE_ETHER = 10n ** 18n;
export const ONE_YEAR = 365 * 24 * 3600;
const quoteRatio=0.6;
const refQuoteRatio=0.7;
export const VolType = [
    'NONE', 'CONST', 'LIVE', 'SPREAD'
]
export const COLLATERAL_FLAG = 1;
export const CALL_FLAG = 1 << 1;
export const PREMIUM_BASE = 1 << 2;
export const PREMIUM_NOTIONAL = 1 << 3;
export const SELLER_ITM = 1 << 4;
export const SELLER_OTM = 1 << 5;
export const BUYER_ITM = 1 << 6;
export const BUYER_OTM = 1 << 7;
export const TradeStatus = ['INVALID', 'OPEN', 'LOCKED', 'SETTLED'];
export const getExpiry = (trade, now) => {
    const { expiry } = trade;
    if (expiry.flag) {
        return expiry.value + now;
    }
    return expiry.value;
}

export const getTau = (trade, now) => {
    if (!trade.expiry.flag) {
        return (Number(trade.expiry.value) - now) / ONE_YEAR;
    } else {
        return Number(trade.expiry.value) / ONE_YEAR;
    }
}
export function compareValue(value1, value2) {
    return (value1 > 0) === (value2 > 0);
}
export function compareFlag(flags, key1, key2) {
    return ((flags & key1) > 0) === ((flags & key2) > 0);
}

export const convertW3Data = (data, decimals, displayDecimals) => {
    // console.log('convert', data, decimals, displayDecimals);
    if (data===null||data===void 0) {
        debugger;
    }
    if (decimals >= displayDecimals) {
        decimals = decimals - displayDecimals;
        return Number(data / (10n ** window.BigInt(decimals))) / Math.pow(10, displayDecimals);
    } else {
        return Number(data / (10n ** window.BigInt(decimals)));
    }
}
export const convertTokenAmt = (amt, token) => {
    const { decimals, displayDecimals } = token;
    return convertW3Data(amt, decimals, displayDecimals);
}
export const parseTokenAmt=(amt,token)=>{
    const { decimals, displayDecimals } = token;
    if(decimals>-displayDecimals){
        return window.BigInt(Math.round(amt * (10 ** displayDecimals))) * (10n ** window.BigInt(decimals - displayDecimals));
    }else{
        return window.BigInt(Math.round(amt * (10 ** displayDecimals))) / (10n ** window.BigInt(displayDecimals - decimals));
    }
    
}
export const convertPercent=(p)=>{
    return Number(p*10000n/ONE_ETHER)/10000;
}
export const parsePercent=(p)=>{
    return window.BigInt(Math.floor(p*10000))*ONE_ETHER/10000n;
}
export const convertW3Price = (p, group,tokens) => {
    const { base, contra, displayDecimals } = group;
    const w3Decimals = 18 + tokens[contra].decimals - tokens[base].decimals;
    return convertW3Data(p, w3Decimals, displayDecimals);
}
export const parseW3Price = (p, group,tokens) => {
    const { base, contra,displayDecimals } = group;
    const w3Decimals = 18 + tokens[contra].decimals - tokens[base].decimals;
    return window.BigInt(Math.floor(p * (10 ** displayDecimals)))* (10n ** window.BigInt(w3Decimals - displayDecimals));
}
export const getStrike = (trade, spot,groups,tokens) => {//get off chain strike
    const { strike } = trade;
    let K = strike.value;
    const group=groups[Number(trade.instrumentId)];
    if (strike.flag) {
        const S=parseW3Price(spot,group,tokens);
        K = S*K/ONE_ETHER;
    }
    K=convertW3Price(K,group,tokens);
    return K;
}
export const getCollateralBN=(trade,chainConfig,spot)=>{
    const collateralInBase= (trade.flags & COLLATERAL_FLAG) >0;
    let collateralAmount;
    if(collateralInBase){
        collateralAmount=trade.notional/ONE_ETHER;
    }else{
        let K;
        if(trade.strike.flag){
            throw new Error('collateral in contra not supported with floating strike');
        }else{
            K=trade.strike.value;
        }
        collateralAmount=(trade.notional/ONE_ETHER)*K/ONE_ETHER;
    }    
    collateralAmount=collateralAmount/window.BigInt(trade.leverage);
    return {
        collateralInBase,
        collateralAmount
    }
}

export const quoteAPY=(spot,r,vol,strike,now,expiry,inBase)=>{
    const amount=inBase?1:strike;
    const tau=(expiry - now) / ONE_YEAR;
    const bsHolder = new BSHolder(spot, strike, r, vol, tau);
    let pv=inBase?BS.call(bsHolder):BS.put(bsHolder);
    pv=Math.max(pv,0);
    if(!!localStorage.getItem('refcode')){
        pv=pv*refQuoteRatio;
    }else{
        pv=pv*quoteRatio;
    }
    if(inBase){
        pv=pv/spot;
    }
    return pv/(tau*amount);
}
export function secondsToWdhms(seconds) {
    var w = Math.floor(seconds / (7*3600*24));
    var d = Math.floor(seconds % (7*3600*24)/(3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    
    var wDisplay = w > 0 ? w + (w === 1 ? " week" : " weeks") : "";
    var dDisplay = d > 0 ? d + (d === 1 ? " day" : " days") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
    return [wDisplay,dDisplay,hDisplay,mDisplay].filter(d=>d!=='').join(',');;
}
export function secondsToWdhmsShort(seconds) {
    var w = Math.floor(seconds / (7*3600*24));
    var d = Math.floor(seconds % (7*3600*24)/(3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    
    var wDisplay = w > 0 ? w + 'w' : "";
    var dDisplay = d > 0 ? d + 'd' : "";
    var hDisplay = h > 0 ? h + 'h' : "";
    var mDisplay = m > 0 ? m + 'm' : "";
    return [wDisplay,dDisplay,hDisplay,mDisplay].filter(d=>d!=='').join('');;
}
export const validateTenorString=(input)=>{
    input=input.trim();
    if(input.endsWith(',')){
        return false;
    }
    if(/\d$/.test(input)){
        return false;
    }
    return true;
}
const secondsUnit={
    's':1,
    'm':60,
    'h':3600,
    'd':3600*24,
    'w':3600*24*7
}

export function extractSecondsFromString(input){
    //\d*\.?\d+|\D+ => 1a2c3.4c=>[1,a,2,c,3.4,c]
    const matches=input.matchAll(/\d*\.?\d+|\D+/g);
    let number=0;
    let count=0;
    let seconds=0;
    for(const match of matches){
        console.log(match[0]);
        if(count%2===0){
            number=parseFloat(match[0]);
            console.log('number',number);
        }else{
            seconds+=number*secondsUnit[match[0]];
        }
        count+=1;
    }
    return seconds;
}   


export const ShareDataContext=createContext({});

export function mrand(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}