import React from "react";
import styles from "./index.module.scss";
import { Button, Dropdown, Flex } from "antd";
import { ReactComponent as MenuIcon } from "../../icons/menu.svg";
import { useWalletClient } from "wagmi";

const MenuItems = [
  { key: "home", label: <a href="/home">Home</a> },
  { key: "dci", label: <a href="/dci">Dual Currency</a> },
];

export const MLayout = ({ children }) => {
  const client = useWalletClient();
  const walletClient = client.data || {};
  const { account = {} } = walletClient;
  const { address } = account;
  return (
    <div className={styles.bg}>
      <div className={styles.header}>
        <img
          src="images/logo.png"
          alt="logo"
          width={66}
          className={styles.logo}
        />
        <Flex align="center">
        {!!address ? <w3m-button /> : <w3m-connect-button />}
        <Dropdown menu={{ items: MenuItems }} overlayClassName={styles.dropdown}>
          <Button className={styles.navBtn}>
            <MenuIcon viewBox="0 0 40 36" height={20} width={20} />
          </Button>
        </Dropdown>
        </Flex>
      </div>
      <main className={styles.main}>{children}</main>
    </div>
  );
};
