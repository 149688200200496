import { useEffect, useState, useMemo, useContext } from "react";
import { useWalletClient } from 'wagmi';
import { getContract, waitForTransaction, prepareWriteContract } from '@wagmi/core';
import { chainConfigs } from "./config";
import IReferral from './abi/Referral.json';
import DCIABI from './abi/DualCurrencyV2.json';
import DCIABINew from './abi/DCI_New.json';
import { DCIColumns, DCIStatus } from './columns';
import { Button, Card, Flex, Table, Typography, Tabs } from "antd";
import { convertTokenAmt, ShareDataContext, mrand } from './common';
import dayjs from "dayjs";
import Icon, { StarOutlined, StarFilled } from '@ant-design/icons';
import { ReactComponent as ARBSVG } from './icons/arb.svg';
import { ReactComponent as BTCSVG } from './icons/btc.svg';
import { ReactComponent as ETHSVG } from './icons/eth.svg';
import { ReactComponent as USDCSVG } from './icons/usdc.svg';
import { ReactComponent as USDTSVG } from './icons/usdt.svg';
import { ReactComponent as OPENSEASVG } from './icons/opensea.svg';

const refercodes=[
    'Z_DEX',
    'Z_DCI',
    'ZDUAL',
    'CURNY',
    'INVST',
    'BLOCK',
    'ZDEFI',
    'CHAIN',
    'SMART'
]
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
const logoMapping = {
    'WETH': ETHSVG,
    'WBTC': BTCSVG,
    'USDC': USDCSVG,
    'USDT': USDTSVG,
    'ARB': ARBSVG
}
export default function Personal({ messageApi,setCurrentStep, setSteps}) {
    const client = useWalletClient();
    const walletClient = client.data || {};
    const { account = {}, chain = {} } = walletClient;
    const { address } = account;
    const { id } = chain;
    const chainConfig = chainConfigs[id] || {};
    const [dciTableData,setDCITableData]=useState([]);
    const [balances, setBalances] = useState([]);
    const shareData = useContext(ShareDataContext);
    const abiJSON=chainConfig.isNewDCI?DCIABINew:DCIABI;
    const load = async () => {
        const balances = [];
        Object.keys(chainConfig.tokens).forEach((tokenAddress) => {
            const token = chainConfig.tokens[tokenAddress];
            const {balance=0,reward=0,address} = shareData[tokenAddress]||{} ;
            balances.push({
                name: token.name,
                balance,
                reward,
                address
            });
            balances.sort((a, b) => a.name.localeCompare(b.name));
            setBalances([...balances]);
        });
        
        const dciResponse=await fetch(`https://${chainConfig.alchemy_name}.g.alchemy.com/nft/v2/${process.env.REACT_APP_ALCHEMY_KEY}/getNFTs?owner=${address}&contractAddresses[]=${chainConfig.dci}&withMetadata=true`);
        const dciJson = await dciResponse.json();
        console.log('nfts dci response',chainConfig.dci,address, dciJson);
        const { ownedNfts:dciNfts } = dciJson;
        const dciNftIds = dciNfts.map(nft => window.BigInt(nft.id.tokenId));
        const dciContract = getContract({
            address: chainConfig.dci,
            abi: abiJSON.abi,
            walletClient: walletClient
        });
        if(dciNftIds.length===0){
            setDCITableData([]);
        }else{
            const dciTrades = await dciContract.read.getTrades([dciNftIds]);
            dciTrades.forEach((trade,index)=>{
                trade.ts=dayjs(dciNfts[index].timeLastUpdated);
            })
            dciTrades.sort((a,b)=>Number(b.id)-Number(a.id));
            console.log('dci trades',dciTrades);
            setDCITableData(dciTrades);
        }
        
    };
    useEffect(() => {
        if (id === undefined || id === null) {
            return;
        }
        load();
    }, [id, address, chainConfig]);
    const tryF = (f) => async (...args) => {
        try {
            return await f(...args);
        } catch (e) {
            console.log('error', e);
            messageApi.error(e.message || e, 5);
        }
    }
    const stopPropagation = f => (e) => {
        e.stopPropagation();
        f();
    }
    const waitTx = async (tx) => {
        const waitResult = await waitForTransaction({
            confirmations: 2,
            hash: tx,
        });
        console.log('waitResult', waitResult);
        if (waitResult.status !== "success") {
            throw new Error('transaction failed');
        }
    }
    const settleDCI=async (trade)=>{
        const group=chainConfig.groups[trade.instrumentId];
        const baseToken=chainConfig.tokens[group.base];
        const contraToken=chainConfig.tokens[group.contra];
        const token=trade.investInBase?baseToken:contraToken;
        setSteps([
            {
                title: 'Settlement',
                description: `settling ${convertTokenAmt(trade.amount,token)}${token.name} Dual Currency Investment`,
            }
        ]);
        setCurrentStep({step:0});
        const result = await prepareWriteContract({
            address: chainConfig.dci,
            abi: abiJSON.abi,
            walletClient: walletClient,
            functionName: 'settle',
            args: [trade.id],
        });
        const dciContract = getContract({
            address: chainConfig.dci,
            abi: abiJSON.abi,
            walletClient: walletClient
        });
        const tx=await dciContract.write.settle([trade.id]);
        await waitTx(tx);
        setCurrentStep({status:'finish',msg:<Typography>Trade settle successfully, you can refresh to view your position </Typography>});
        await load();
    }
    const burnDCI=async (tokenId,withdraw)=>{
        const contract = getContract({
            address: chainConfig.dci,
            abi: abiJSON.abi,
            walletClient: walletClient
        });
        await prepareWriteContract({
            address: chainConfig.dci,
            abi: abiJSON.abi,
            walletClient: walletClient,
            functionName: 'burn',
            args: [tokenId],
        });
        if(withdraw){
            messageApi.info(`requesting to withdraw Dual Currency token ${tokenId}`);
        }else{
            messageApi.info(`deleting Dual Currency token ${tokenId}`);
        }
        
        const tx = await contract.write.burn([tokenId]);
        console.log(tx);
        await waitTx(tx);
        if(withdraw){
            messageApi.info(`Dual Currency token ${tokenId} withdraw request submitted`);
        }else{
            messageApi.info(`Dual Currency token ${tokenId} deleted`);
        }
        
    }
    const dciColumns = useMemo(() => {
        const cols= DCIColumns(0, chainConfig.tokens, chainConfig.groups);
        cols.push({
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => {
                const status = DCIStatus[record.status];
                const now = dayjs().unix();
                const expiry = record.expiry.flag ? Number(record.expiry) + now : Number(record.expiry);
                const canSettle= status==='OPEN' && now >= expiry;
                const canWithdraw= status==='OPEN';
                const canBurn = status==='SETTLED';
                const canResale= status!=='SETTLED';
                return <>
                    
                    {canSettle&&<Button className="action-btn" onClick={stopPropagation(tryF(async () => await settleDCI(record)))} size='small'>Settle</Button>}
                    {canWithdraw&&<Button className="action-btn" onClick={stopPropagation(tryF(async () => await burnDCI(record.id,true)))} size='small'>Withdraw</Button>}
                    {canResale&&<Button className="action-btn" onClick={stopPropagation(tryF(() => window.open(chainConfig.opensea_url(chainConfig.dci,record.id),'_blank')))} size='small' icon={<Icon component={OPENSEASVG}/>}>NFT</Button>}
                    {canBurn&&<Button className="action-btn" onClick={stopPropagation(tryF(async () => await burnDCI(record.id,false)))} size='small'>Delete</Button>}
                </>
            }
        })
        return cols;
    }, [chainConfig]);
    
    const registerReferral=async ()=>{
        const referralContract = getContract({
            address: chainConfig.referral,
            abi: IReferral.abi,
            walletClient: walletClient
        });
        const code=refercodes[getRandomInt(refercodes.length)];
        messageApi.info(`registering referral code ${code}`);
        const tx=await referralContract.write.register([code]);
        await waitTx(tx);
        const mycode=await referralContract.read.getReferralCode([address]);
        messageApi.success(`registered referral code ${mycode}`);
        shareData.refcode=mycode;
    };
    const showRewards=!!shareData.refcode;
    const copyReferralCode = async () => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.searchParams.delete('refcode');
        url.searchParams.append('refcode', shareData.refcode);
        await navigator.clipboard.writeText(url.toString());
        messageApi.success('Referral code copied to clipboard');
    };
    return <div>
        {!id&&<Flex className="wc-placeholder" align="center" justify="center" vertical>
            <w3m-connect-button />
            <Typography.Title level={3}>Please connect to wallet to proceed</Typography.Title>
        </Flex>}
        {id&&<><Card title='Token Balance'>
            <Flex align="center" wrap="wrap">
                {balances.map((balance, index) => {
                    return <Card key={balance.name} className="balance-card" size='small'>
                        <div style={{fontSize:'20px'}}>
                        <Icon component={logoMapping[balance.name]}/>
                        &nbsp;
                        {balance.name}
                        </div>
                        <Flex align="end" className="balance-row margin-t20">
                            <Typography className="balance-label">
                            Balance:
                            </Typography>
                            <Typography.Title level={4}>
                            {balance.balance}
                            </Typography.Title>
                        </Flex>
                        {showRewards&&<Flex align="end" className="balance-row">
                                <Typography className="balance-label">
                                Reward:
                                </Typography>
                                <Typography.Title level={4}>
                                {balance.reward}
                                </Typography.Title>
                            </Flex>
                        }
                    </Card>
                })}
                {shareData.refcode&&<Card className="balance-card pointer" size='small' onClick={tryF(copyReferralCode)}>
                    <div style={{fontSize:'20px'}}>
                        <StarFilled/>
                        &nbsp;
                        Rewards
                        </div>
                        <Flex align="end" className="balance-row margin-t20">
                            <Typography className="balance-label">
                            Referral Code:
                            </Typography>
                            <Typography.Title level={4}>
                            {shareData.refcode}
                            </Typography.Title>
                        </Flex>
                        <Flex align="end" className="balance-row">
                            <Typography.Title level={4}>
                            Click to copy
                            </Typography.Title>
                        </Flex>
                </Card>}
                {!shareData.refcode&&<Card className="balance-card pointer" size='small' onClick={tryF(registerReferral)}>
                    <div style={{fontSize:'20px'}}>
                        <StarOutlined/>
                        &nbsp;
                        Rewards
                        </div>
                        <Flex align="end" className="balance-row margin-t20">
                            <Typography className="balance-label">
                            Referral Code:
                            </Typography>
                            <Typography.Title level={5}>
                            click to register
                            </Typography.Title>
                        </Flex>
                        
                </Card>}
            </Flex>
        </Card>
        <Card title='My Trades' className="trades-collections">
            <Tabs items={[
                {
                    label: 'Dual Currency Trades',
                    key: 'dci',
                    children: <Table dataSource={dciTableData} chainConfig={chainConfig} columns={dciColumns} size="large"
                    rowKey='tokenId' rowClassName='trade-list-row'
                />
                }   
            ]}>

            </Tabs>
            
        </Card>
        </>
        }
    </div>
}