import { useEffect,useState } from "react";
import { getContract } from '@wagmi/core';
import DCIJSON from "./abi/DualCurrencyV2.json";
import { parseW3Price,parsePercent,parseTokenAmt, convertPercent, convertTokenAmt } from "./common";
import { Form,InputNumber, Typography } from "antd";
import dayjs from "dayjs";
const TZ=new Date().getTimezoneOffset();
export const DCI=({lot,setLot,walletClient,group,chainConfig,expiry,strike})=>{
    const [apy,setApy]=useState(0);
    const [unitInterest,setUnitInterest]=useState(0);
    const investToken=group.isBase?chainConfig.tokens[group.base]:chainConfig.tokens[group.contra];
    const exerciseToken=group.isBase?chainConfig.tokens[group.contra]:chainConfig.tokens[group.base];
    useEffect(()=>{
        if(!(walletClient&&chainConfig&&chainConfig.dci)){
            return;
        }
        const load=async()=>{
            const dciContract=getContract({
                address: chainConfig.dci,
                abi: DCIJSON.abi,
                walletClient: walletClient
            });
            const instrumentId=group.id;
            const investInBase=group.isBase;
            const K=parseW3Price(strike,group,chainConfig.tokens);
            const refCode=localStorage.getItem('refcode')||'';
            const result=await dciContract.read.quote([instrumentId,expiry,investInBase,K,refCode]);
            console.log('result',result);
            const apy=convertPercent(result[0])/10000;
            const unitInterest= convertPercent(result[1]);
            console.log('apy',apy,'unitInterest',unitInterest);
            setApy(apy);
            setUnitInterest(unitInterest);
        }
        load();
    },[chainConfig,expiry,group,strike,walletClient]);
    const updateLot=(value)=>{
        const lot=Math.round(Math.max(1,value));
        setLot(lot);
    }
    const lotSize=convertTokenAmt(window.BigInt(group.lotSize),investToken);
    const interest=lot*lotSize*unitInterest;
    const investAmount=lot*lotSize * (1+unitInterest);
    const exerciseAmount=group.isBase?(investAmount*strike):(investAmount/strike);
    console.log('expiry',expiry);
    return <Form layout="horizontal" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} className="overflow-auto" labelAlign='left' size='small'>
        <Form.Item label="Settlement Date" value='expiry'>
            <Typography>{dayjs.unix(expiry).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        </Form.Item>
        <Form.Item label="Target Price" value='strike'>
            <Typography>{strike}</Typography>
        </Form.Item>
        
        <Form.Item label="Invest Amount" value='amount'>
            <InputNumber value={lot} onChange={updateLot} />
            <span className="ant-form-text" style={{ marginLeft: 8 }}>
                x{convertTokenAmt(window.BigInt(group.lotSize),investToken)} {investToken.name}
            </span>
        </Form.Item>
        <Form.Item label="APY" value='apy'>
            <Typography>{(apy*100).toFixed(2)}%</Typography>
        </Form.Item>
        <Form.Item label="Interest" value='interest'>
            <Typography>{interest.toFixed(investToken.displayDecimals)} {investToken.name}</Typography>
        </Form.Item>
        {
            group.isBase?(
                <Form.Item label={`if price<=${strike}, you will receive`} value='amount'>
                    <Typography>{investAmount.toFixed(investToken.displayDecimals)} {investToken.name}</Typography>
                </Form.Item>
            ):(
                <Form.Item label={`if price>=${strike}, you will receive`} value='amount'>
                    <Typography>{investAmount.toFixed(investToken.displayDecimals)} {investToken.name}</Typography>
                </Form.Item>
            )
        }
        {
            group.isBase?(
                <Form.Item label={`if price>${strike}, you will receive`} value='amount'>
                    <Typography>{exerciseAmount.toFixed(exerciseToken.displayDecimals)} {exerciseToken.name}</Typography>
                </Form.Item>
            ):(
                <Form.Item label={`if price<${strike}, you will receive`} value='amount'>
                    <Typography>{exerciseAmount.toFixed(exerciseToken.displayDecimals)} {exerciseToken.name}</Typography>
                </Form.Item>
            )
        }
        

    </Form>
};