import { Layout, Space, Menu } from "antd";

import { Outlet, Link } from "react-router-dom";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig, useWalletClient,configureChains } from "wagmi";
import { getContract } from "@wagmi/core";
import { arbitrum, mainnet,polygon } from "wagmi/chains";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { useEffect, useState } from "react";
import {
  ShareDataContext,
  convertPercent,
  convertW3Price,
  convertTokenAmt,
} from "./common";
import MarketDataV1 from "./abi/MarketDataV1.json";
import IActions from "./abi/IActions.json";
import IReferral from "./abi/Referral.json";
import ERCABI from "./abi/erc20.json";
import { chainConfigs } from "./config";
import { isMobile } from "react-device-detect";
import { MLayout } from "./mobile/MLayout";

const projectId = process.env.REACT_APP_PROJECT_ID;
// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "ZDeX Dual Currency Investment",
  url: "https://app.zdex.fi",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const { chains, publicClient } = configureChains(
  [polygon,arbitrum, mainnet],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY })],
)
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
wagmiConfig.setPublicClient(publicClient);
const themeVariables = {
  "--w3m-accent":
    "linear-gradient(127.51deg, rgb(252, 73, 130) 1.151%,rgb(81, 86, 254) 99.939%)",
};
// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables });
const { Header, Content } = Layout;
const HeaderBar = () => {
  const client = useWalletClient();
  const walletClient = client.data || {};
  const { account = {} } = walletClient;
  const { address } = account;
  return (
    <>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["dci"]}
        className="header-menu"
        items={[
          {
            key: "dci",
            label: (
              <Link to="/dci">
                <b>Dual Currency</b>
              </Link>
            ),
          },
          {
            key: "home",
            label: (
              <Link to="/home">
                <b>Portfolio</b>
              </Link>
            ),
          },
          { key: "space", lable: <div style={{ flexGrow: 1 }} /> },
          {
            key: "button",
            label: (
              <Space align="end">
                {!!address ? <w3m-button /> : <w3m-connect-button />}
              </Space>
            ),
            className: "wc-buttons",
            disabled: true,
          },
        ]}
      />
    </>
  );
};

function ContextWrapper() {
  const client = useWalletClient();
  const walletClient = client.data || {};
  const { chain = {}, account = {} } = walletClient;
  const { id = 42161 } = chain;
  const { address } = account;
  const [shareData, setShareData] = useState({});
  useEffect(() => {
    if (id === null || id === void 0) {
      return;
    }
    const chainConfig = chainConfigs[id] || {};
    if (!chainConfig.marketData) {
      return;
    }
    const shareData = {};
    const getBalance = async (token) => {
      console.log("erc getBalance for ", token, address);
      const ercContract = getContract({
        address: token.address,
        abi: ERCABI,
        walletClient: walletClient,
      });
      const tokenBalance = await ercContract.read.balanceOf([address]);
      return tokenBalance;
    };
    const loadMarketData = async () => {
      const marketDataContract = getContract({
        address: chainConfig.marketData,
        abi: MarketDataV1.abi,
        walletClient: walletClient,
      });
      const marketData = await Promise.all(
        (chainConfig.groups || []).map(async (group) => {
          console.log('address',chainConfig.marketData,"group", group.id)
          const data = await marketDataContract.read.getData([group.id]);
          const [_price, _r, _vol] = data;
          const r = convertPercent(_r);
          const vol = convertPercent(_vol);
          const price = convertW3Price(_price, group, chainConfig.tokens);
          return {
            r,
            vol,
            spot: price,
            ...group,
          };
        })
      );
      marketData.forEach((cur) => {
        shareData[cur.id] = cur;
      });
      console.log("marketData", marketData);
      setShareData({ ...shareData });
    };
    const load = async () => {
      await loadMarketData();
      if (address) {
        const balances = await Promise.all(
          Object.keys(chainConfig.tokens)
            .filter((k) => !chainConfig.tokens[k].virtual)
            .map(async (tokenAddress) => {
              const token = chainConfig.tokens[tokenAddress];
              const balance = await getBalance(token);
              return {
                name: token.name,
                address: tokenAddress,
                balance: convertTokenAmt(balance, token),
              };
            })
        );
        balances.forEach((balance) => {
          shareData[balance.address] = balance;
        });
        setShareData({ ...shareData });
        const referralContract = getContract({
          address: chainConfig.referral,
          abi: IReferral.abi,
          walletClient: walletClient,
        });
        const refcode = await referralContract.read.getReferralCode([address]);
        console.log("refcode", refcode);
        if (refcode) {
          shareData.refcode = refcode;
          setShareData({ ...shareData });
          const tokenList = Object.keys(chainConfig.tokens).reduce(
            (mem, cur) => {
              mem.push(chainConfig.tokens[cur]);
              return mem;
            },
            []
          );
          const actionsContract = getContract({
            address: chainConfig.actions,
            abi: IActions.abi,
            walletClient: walletClient,
          });
          const tokenAddressList = tokenList.map((token) => token.address);
          console.log("tokenAddressList", tokenAddressList);
          const rewardsList = await actionsContract.read.getRewardsFor([
            address,
            tokenAddressList,
          ]);
          tokenList.forEach((token, index) => {
            shareData[token.address].reward = convertTokenAmt(
              rewardsList[index],
              token
            );
          });
          setShareData({ ...shareData });
        }
      }
    };
    load();
    const intervalId=setInterval(loadMarketData, 30000);
    return ()=>clearInterval(intervalId);
  }, [id, address]);
  return (
    <ShareDataContext.Provider value={shareData}>
      <Outlet />
    </ShareDataContext.Provider>
  );
}

export default function Root() {
  return (
    <WagmiConfig config={wagmiConfig}>
      {isMobile === false && (
        <Layout className="layout">
          <Header
            style={{
              display: "flex",
              alignItems: "center",
              background: "#13111a",
              paddingLeft: 0,
            }}
          >
            <HeaderBar />
          </Header>
          <Content
            style={{
              padding: "5px",
              flex: 1,
              background: "#13111a",
              color: "white",
              border: "none",
            }}
          >
            <ContextWrapper>
              <Outlet />
            </ContextWrapper>
          </Content>
        </Layout>
      )}
      {isMobile && (
        <MLayout>
          <ContextWrapper>
            <Outlet />
          </ContextWrapper>
        </MLayout>
      )}
    </WagmiConfig>
  );
}
