export const ADDRESS_1='0x0000000000000000000000000000000000000001'
export const chainConfigs={
    42161:{
        alchemy_name: 'arb-mainnet',
        opensea_url:(address,tokenId)=>`https://opensea.io/assets/arbitrum/${address}/${tokenId}`,
        marketData: '0x043B3Ba877a82Cfe6e55df1eff8d951b22f9B10f',
        referral: '0xf810893ee4d8De481Cae3FA9df04a661dA16d34B',        
        dci: '0x7E484a37003fb7Fcb7f78490b04dca9ab398Cc3a',
        tokens:{
            '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f':{
                name:'WBTC',
                decimals:8,
                displayDecimals:5,
            },
            '0xaf88d065e77c8cC2239327C5EDb3A432268e5831':{
                name:'USDC',
                decimals:6,
                displayDecimals:3,
            },
            '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1':{
                name:'WETH',
                decimals:18,
                displayDecimals:5,
                native:true,
                zeroAllowance: false
            },
            '0x912CE59144191C1204E64559FE8253a0e49E6548':{
                name:'ARB',
                decimals:18,
                displayDecimals:3,
            },
            '0x0000000000000000000000000000000000000000':{
                name:'SPY',
                decimals:8,
                displayDecimals:4,
                virtual: true
            }
        },
        groups:[
            {
                id: 0n,
                name: 'WBTC-USDC',
                base: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
                contra: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
                displayDecimals: 0,
                minAmt: 1e5
            },
            {
                id: 1n,
                name: 'WETH-USDC',
                base: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                contra: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
                displayDecimals: 1,
                minAmt: 1e15
            },{
                id: 2n,
                name: 'SPY-USDC (Cash)',
                base: '0x0000000000000000000000000000000000000000',
                contra: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
                displayDecimals: 1,
                minAmt: 1e5
            }
        ],
        dci_groups:[
            {
                id: 0n,
                key: 'WBTC-USDC-BASE',
                isBase: true,
                name: 'Sell WBTC for USDC',
                base: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
                contra: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
                displayDecimals: 0,
                lotSize: 1e4
            },
            {
                id: 0n,
                key: 'WBTC-USDC-CONTRA',
                isBase: false,
                name: 'Buy WBTC with USDC',
                base: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
                contra: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
                displayDecimals: 0,
                lotSize: 1e6
            },
        ]
    },
    1: {
        alchemy_name: 'eth-mainnet',
        opensea_url:(address,tokenId)=>`https://opensea.io/assets/${address}/${tokenId}`,
        groups:[],
        tokens:{}
    },
    137:{
        alchemy_name: 'polygon-mainnet',
        opensea_url:(address,tokenId)=>`https://opensea.io/assets/matic/${address}/${tokenId}`,
        marketData: '0xdE867375E402A310713c342F3FDf478e1F1D684A',
        referral: '0x4E9ca35464c4831007c60336e79073100508D929',
        dci: '0x0b0F56F34Db1eC52dEf505088aA04cB2CCf37797',
        gas: 1500000,
        isNewDCI: true,
        tokens:{
            '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6':{
                name:'WBTC',
                decimals:8,
                displayDecimals:5,
            },
            '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174':{
                name:'USDC',
                decimals:6,
                displayDecimals:3,
            },
            '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619':{
                name:'WETH',
                decimals:18,
                displayDecimals:5,
                native:false,
                zeroAllowance: false
            }
        },
        groups:[
            {
                id: 0n,
                name: 'WBTC-USDC',
                base: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
                contra: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                displayDecimals: 0,
                minAmt: 1e5
            },
            {
                id: 1n,
                name: 'WETH-USDC',
                base: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                contra: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                displayDecimals: 1,
                minAmt: 1e15
            }
        ],
        dci_groups:[
            {
                id: 0n,
                key: 'WBTC-USDC-BASE',
                isBase: true,
                name: 'Sell WBTC for USDC',
                base: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
                contra: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                displayDecimals: 0,
                lotSize: 1e4
            },
            {
                id: 0n,
                key: 'WBTC-USDC-CONTRA',
                isBase: false,
                name: 'Buy WBTC with USDC',
                base: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
                contra: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                displayDecimals: 0,
                lotSize: 1e6
            },
            {
                id: 1n,
                key: 'WETH-USDC-BASE',
                isBase: true,
                name: 'Sell WETH for USDC',
                base: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                contra: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                displayDecimals: 0,
                lotSize: 1e15
            },
            {
                id: 1n,
                key: 'WETH-USDC-CONTRA',
                isBase: false,
                name: 'Buy WETH with USDC',
                base: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                contra: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                displayDecimals: 0,
                lotSize: 1e6
            }
        ]
    }
}
Object.keys(chainConfigs).forEach(chainId=>{
    const chainConfig=chainConfigs[chainId];
    Object.keys(chainConfig.tokens).forEach(tokenAddress=>{
        const token=chainConfig.tokens[tokenAddress];
        token.address=tokenAddress;
        token.chainId=chainId;
    })
});
console.log(chainConfigs);
export const getGroup=(chainId,groupId)=>{
    return chainConfigs[chainId].groups[groupId];
}
export const getToken=(chainId,tokenAddress)=>{
    return chainConfigs[chainId].tokens[tokenAddress];
}

