import dayjs from "dayjs";
import { Tabs, Table, Checkbox, Typography, Flex, Modal, Button } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CALL_FLAG, convertTokenAmt, ONE_ETHER, ShareDataContext, quoteAPY, parseTokenAmt,parseW3Price } from "./common";
import { DCI } from './DCI';
import { useWalletClient } from 'wagmi';
import { getContract,waitForTransaction,fetchBalance,prepareWriteContract,writeContract } from '@wagmi/core';
import { chainConfigs, ADDRESS_1 } from "./config";
import DCIJSON from "./abi/DualCurrencyV2.json";
import WETHABI from './abi/weth.json';
import ERCABI from './abi/erc20.json';


const mapDateToLabel=(date)=>{
    return {
        until: date.unix(),
        label: date.format('YYYY-MM-DD')
    }
}
const steps=[0.1,0.5,1,5,50,100,200,250,500,1000];
const roundSteps=(step)=>{
    for(let i=0;i<steps.length;i++){
        if(step<steps[i]){
            return steps[i];
        }
    }
}


const refreshDates=()=>{
    const dates=[];
    const startOfNextWeek=dayjs().add(1,'week').set('day',0).set('hour',0).set('minute',0).set('second',0).set('millisecond',0);
    // const eodThisFriday=startOfNextWeek.subtract(1,'second').subtract(1,'day').subtract(8,'hours'); //UTC Friday 8am
    const eodThisFriday=dayjs().add(6,'hours');
    if(eodThisFriday.subtract(5,'hours').isAfter(dayjs())){
        dates.push(mapDateToLabel(eodThisFriday));
    }
    dates.push(mapDateToLabel(eodThisFriday.add(1,'week')));
    dates.push(mapDateToLabel(eodThisFriday.add(2,'week')));
    dates.push(mapDateToLabel(eodThisFriday.add(3,'week')));
    dates.push(mapDateToLabel(eodThisFriday.add(1,'month')));
    dates.push(mapDateToLabel(eodThisFriday.add(2,'month')));
    dates.push(mapDateToLabel(eodThisFriday.add(3,'month')));
    dates.push(mapDateToLabel(eodThisFriday.add(6,'month')));
    dates.push(mapDateToLabel(eodThisFriday.add(1,'year')));
    return dates;
}
const refreshStrikes=(price,isBase)=>{
    if(price===null){
        return;
    }
    const priceStep=roundSteps(price*10/2000);
    const steps=isBase?[-1,0,1,2,3,4,5,6,7,8,10,15]:[2, 1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -10, -15];
    const strikes=steps.map(step=> (Math.floor(price/priceStep)+step)*priceStep).filter(strike=>strike>0);
    return strikes;
}
const DCITab=({group,chainConfig,walletClient,messageApi,setCurrentStep,setSteps})=>{
    console.log('group',group);
    const { account = {} } = walletClient;
    const { address } = account;
    const [dates,setDates]=useState([]);
    const [selected,setSelected]=useState({});
    const [data,setData]=useState([]);
    const [price,setPrice]=useState(0);
    
    const [lot,setLot]=useState(1);
    const [openModal,setOpenModal]=useState(false);
    const [modalData,setModalData]=useState({});
    const shareData=useContext(ShareDataContext);
    const baseToken=chainConfig.tokens[group.base];
    const contraToken=chainConfig.tokens[group.contra];
    useEffect(()=>{
        const price=shareData[group.id]?.spot;
        if(price===undefined){
            return
        }
        const dates=refreshDates();
        const strikes=refreshStrikes(price,group.isBase);
        //set data
        const data=[];
        const r=shareData[group.id]?.r;
        const vol=shareData[group.id]?.vol;
        const now=dayjs().unix();
        dates.forEach(date=>{
            strikes.forEach(strike=>{
                const apy=quoteAPY(price,r,vol,strike,now,date.until,group.isBase);
                const entry={
                    key: `${date.label}-${strike}`,
                    strike,
                    apy: apy,
                    expiry: date.until,
                    expiryDate: date.label,
                };
                data.push(entry);
            });
        });
        setDates(dates);
        setPrice(price);
        setData(data);
        if(Object.keys(selected).length===0){   
            const selection={};
            for(let i=0;i<5;i++){
                selection[dates[i].label]=true;
            }
            setSelected(selection);
        }
    },[shareData]);
    
    const filteredData=data.filter(entry=>selected[entry.expiryDate]);
    const columns=[
        {
            title: 'TargetPrice',
            dataIndex: 'strike',
            align: 'center',
            width: 200,
            sorter: (a, b) => a.strike-b.strike,
        },{
            title: 'APY',
            dataIndex: 'apy',
            align: 'center',
            width: 200,
            render:(apy)=>{
                return <span>{(apy*100).toFixed(2)}%</span>
            },
            sorter: (a, b) => a.apy-b.apy
        },{
            title: 'Settlement Date',
            dataIndex: 'expiry',
            align: 'center',
            width: 200,
            render:(expiry)=>{
                return <span>{dayjs.unix(expiry).format('YYYY-MM-DD')}</span>
            },
            sorter: (a, b) => a.expiry-b.expiry
        },{
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            width: 200,
            render:(v,record)=>{
                const onClick=async()=>{
                    const modalData={
                        ...record,
                        group,
                        chainConfig,
                        walletClient,
                        messageApi,
                        setCurrentStep,
                        setSteps
                    };
                    setModalData(modalData);
                    setOpenModal(true);
                };
                return <Button type='primary' onClick={onClick}>
                    Subscribe
                </Button>
            }
        }
    ];
    
    const closeModal=()=>{
        setOpenModal(false);
    }
    const tryF = (f) => async (...args) => {
        try {
            return await f(...args);
        } catch (e) {
            console.log('error', e);
            messageApi.error(e.message || e, 5);
            setCurrentStep({ msg: e.message || e, status: 'error' });
        }
    }
    const getBalance = async (token) => {
        console.log('erc getBalance for ', token, address);
        const ercContract = getContract({
            address: token.address,
            abi: ERCABI,
            walletClient: walletClient
        });
        const tokenBalance = await ercContract.read.balanceOf([address]);
        return tokenBalance;
    }
    const waitTx = async (tx) => {
        const waitResult = await waitForTransaction({
            confirmations: 2,
            hash: tx,
        });
        console.log('waitResult', waitResult);
        if (waitResult.status !== "success") {
            throw new Error('transaction failed');
        }
    }
    const convertToWeth = async (token, amount) => {

        const wethContract = getContract({
            address: token.address,
            abi: WETHABI,
            walletClient: walletClient
        });
        const tx = await wethContract.write.deposit({ value: amount });
        console.log('tx', tx);
        await waitTx(tx);

    }
    const ensureBalance = async (token, amount) => {
        const balance = await getBalance(token);
        console.log('balance', token.name, balance);
        if (balance >= amount) {
            return;
        }
        if (token.native) {
            const nativeBalance = await fetchBalance({ address: address });
            console.log('native balance', nativeBalance);
            if (nativeBalance.value + balance >= amount) {
                await convertToWeth(token, amount - balance);
                return;
            } else {
                throw new Error(`Insufficient ${token.name} balance, balance=${convertTokenAmt(nativeBalance.value + balance, token)}, require=${convertTokenAmt(amount, token)}`);
            }
        } else {
            throw new Error(`Insufficient ${token.name} balance, balance=${convertTokenAmt(balance, token)}, require=${convertTokenAmt(amount, token)}`);
        }
    }
    const ensureAllowance = async (spender,token, amount) => {
        await ensureBalance(token, amount);
        console.log('ensure balance completed');
        const ercContract = getContract({
            address: token.address,
            abi: ERCABI,
            walletClient: walletClient
        });
        const allowance = await ercContract.read.allowance([address, spender]);
        if (allowance >= amount) {
            return true;
        } else {
            if (token.zeroAllowance) {
                const tx = await ercContract.write.approve([spender, 0]);
                await waitTx(tx);
            }
            const tx = await ercContract.write.approve([spender, amount]);
            await waitTx(tx);
            return true;
        }
    }
    const subscribe=async()=>{
        const {expiry,strike}=modalData;
        const dciContract=getContract({
            address: chainConfig.dci,
            abi: DCIJSON.abi,
            walletClient: walletClient
        });
        const investToken=group.isBase?chainConfig.tokens[group.base]:chainConfig.tokens[group.contra];
        const defiAmount=window.BigInt(Math.round(lot*group.lotSize));
        setSteps([
            {
                title: 'Approval of token spending',
                description: `permitting deposit of ${convertTokenAmt(defiAmount, investToken)}${investToken.name}`,
            }, {
                title: 'Dual Currency Investment',
                description: `subscribing ${convertTokenAmt(defiAmount, investToken)}${investToken.name} Dual Currency Investment with target price ${strike} and settlement date ${dayjs.unix(expiry).format('YYYY-MM-DD')}`,
            }
        ]);
        setCurrentStep({ step: 0 });
        await ensureAllowance(chainConfig.dci,investToken, defiAmount);
        setCurrentStep({ step: 1 });
        
        const K=parseW3Price(strike,group,chainConfig.tokens);
        
        const refCode=localStorage.getItem('refcode')||'';
        const payload={
            address: chainConfig.dci,
            abi: DCIJSON.abi,
            functionName: 'subscribe',
            args: [group.id,defiAmount,expiry,group.isBase,K,refCode]
        };
        if(chainConfig?.gas){
            payload.gas=chainConfig.gas;
        }
        const result = await prepareWriteContract(payload);
        console.log('estimate result', result);
        const { request }=result;
        const tx = await writeContract(request);
        console.log('tx', tx);
        await waitTx(tx.hash || tx);
        
        setCurrentStep({ step: 2,status: 'finish', msg: <Typography>DCI subscribed successfully, you can view your subscription in <Link to="/home">Portfolio</Link> page </Typography> });
    }
    return <Flex vertical>
        <div className="expiry-date-row">
            <Flex wrap="wrap">
                <Typography className="index-item">Index Price: {price}</Typography>
                <Flex>
                    <Typography className="index-item1">Balance:</Typography>
                    <Typography className="index-item"> {shareData[group.base]?.balance}({baseToken.name}), {shareData[group.contra]?.balance}({contraToken.name})</Typography>
                </Flex>
            </Flex>
        </div>
        <div className="expiry-date-row">
            {dates.map(date=>{
                return <Checkbox className='expiry-date-item' key={date.label} checked={selected[date.label]} onChange={(e)=>{
                    setSelected({
                        ...selected,
                        [date.label]:e.target.checked
                    });
                }}>{date.label}</Checkbox>
            })}
        </div>
        <Table columns={columns} dataSource={filteredData} scroll={{x: 'max-content',y:'max-content'}}
        />
        <Modal open={openModal} title={group.name} onOk={tryF(subscribe)} onCancel={closeModal} okText='Subscribe' width={550} centered zIndex={200}>
            <DCI {...modalData} lot={lot} setLot={setLot} onCancel={closeModal} walletClient={walletClient} messageApi={messageApi} setCurrentStep={setCurrentStep} setSteps={setSteps}/>
        </Modal>
        </Flex>
}
export default function DCIPanel({messageApi,setCurrentStep,setSteps}) {
    const client = useWalletClient();
    const walletClient = client.data || {};
    const { account = {}, chain = {} } = walletClient;
    const { address } = account;
    const { id=42161 } = chain;
    const chainConfig=chainConfigs[id]||{};
    const [trades,setTrades]=useState([]);
    
    
    return <div>
        <Tabs defaultActiveKey="1" centered items={chainConfig.dci_groups?.map(group=>({
            key:group.key,
            label:group.name,
            children:<DCITab group={group} chainConfig={chainConfig} address={address} 
                walletClient={walletClient}
                messageApi={messageApi} setCurrentStep={setCurrentStep} setSteps={setSteps}/>
        }))}/>
        
    </div>
}