import { Typography, Tag } from "antd";
import {
    CALL_FLAG, PREMIUM_BASE, COLLATERAL_FLAG, convertW3Price, convertTokenAmt, secondsToWdhms, convertPercent, VolType, ONE_ETHER
} from "./common";
import dayjs from "dayjs";
export const columns = (shareData, tokens, groups) => [{
    title: 'NFT id',
    dataIndex: 'tokenId',
    key: 'tokenId',
    render: (value) => {
        return <Typography>{Number(value)}</Typography>
    }
},
{
    title: 'CcyPair',
    dataIndex: 'instrumentId',
    key: 'instrumentId',
    render: (value) => {
        return <Typography>{groups[value].name}</Typography>
    }
}, {
    title: 'Type',
    key: 'type',
    render: (_, record) => {
        if (record.flags & CALL_FLAG) {
            return <Typography>Call</Typography>
        } else {
            return <Typography>Put</Typography>
        }
    }
}, {
    title: 'Notional',
    key: 'notional',
    dataIndex: 'notional',
    render: (value, record) => {
        return <Typography>{convertTokenAmt(value / ONE_ETHER, tokens[groups[record.instrumentId].base])}</Typography>
    }
}, {
    title: 'Expiry',
    key: 'expiry',
    dataIndex: ['expiry', 'value'],
    render: (value, record) => {
        if (record.expiry.flag) {
            const now = dayjs().unix();
            return <Typography>{dayjs.unix(Number(value) + now).format('YYYY-MM-DD HH:mm:ss')} <br /> ({secondsToWdhms(Number(value))})</Typography>
        } else {
            return <Typography>{dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }

    }
},
{
    title: 'Strike',
    key: 'strike',
    dataIndex: ['strike', 'value'],
    render: (value, record) => {
        return <Typography>{convertW3Price(value, groups[record.instrumentId], tokens)}</Typography>
    }
}, {
    title: 'Leverage',
    key: 'leverage',
    dataIndex: 'leverage',
    render: (value, record) => {
        const isCall = !!(record.flags & CALL_FLAG);
        const isCollateralInBase = !!(record.flags & COLLATERAL_FLAG);
        if (value === 1 && (isCall === isCollateralInBase)) {
            return <Typography>-</Typography>
        } else {
            return <Typography>{value}X</Typography>
        }
    }
},
{
    title: 'Premium',
    key: 'premium',
    dataIndex: 'premium',
    render: (value, record) => {
        const isBase = record.flags & PREMIUM_BASE;
        const group = groups[record.instrumentId];
        const baseToken = tokens[group.base];
        const contraToken = tokens[group.contra];

        if (VolType[record.volType] === 'NONE') {
            if (isBase) {
                return <Typography>{convertTokenAmt(value / ONE_ETHER, baseToken)} ({baseToken.name}) </Typography>
            } else {
                return <Typography>{convertTokenAmt(value / ONE_ETHER, contraToken)} ({contraToken.name}) </Typography>
            }
        } else {
            if (isBase) {
                const spot = shareData[record.instrumentId].spot;
                return <Typography>{(record.V / spot).toFixed(baseToken.displayDecimals)} {baseToken.name}<br /> ({(convertPercent(value) * 100).toFixed(2)}%Vol)</Typography>
            } else {
                return <Typography>{record.V.toFixed(contraToken.displayDecimals)} {contraToken.name}<br /> ({(convertPercent(value) * 100).toFixed(2)}%Vol)</Typography>
            }

        }

    }
}, {
    title: 'Position',
    key: 'buysell',
    dataIndex: 'buysell',
    render: (value, _) => {
        return <Typography>{value}</Typography>
    }
}
];

/**
 * amount
: 
1000000n
amountExpiry
: 
1000582n
expiry
: 
1702222271n
hedgeId
: 
0n
hedgeType
: 
1
id
: 
0n
instrumentId
: 
0n
investInBase
: 
false
referral
: 
"0x0000000000000000000000000000000000000000"
referralAmt
: 
0n
settlementPrice
: 
0n
status
: 
1
strike
: 
430000000000000000000n
 * @param {*} shareData 
 * @param {*} tokens 
 * @param {*} groups 
 * @returns 
 */
export const DCIStatus = [
        'INVALID',
        'LOCKED',
        'REQUEST_WITHDRAW',
        'WITHDRAWN',
        'EXERCISED',
        'EXPIRED'
]
export const DCIColumns = (shareData, tokens, groups) => [
    {
        title: 'NFT id',
        dataIndex: 'id',
        key: 'id',
        render: (value) => {
            return <Typography>{Number(value)}</Typography>
        }
    },
    {
        title: 'Subscription Time',
        key: 'ts',
        dataIndex: 'ts',
        render: (value, record) => {
            return <Typography>{value.format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }
    },
    {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        render: (value, record) => {
            const group = groups[record.instrumentId];
            const token = record.investInBase ? tokens[group.base] : tokens[group.contra];
            return <Typography>{convertTokenAmt(value, token)} {token.name}</Typography>
        }
    }, {
        title: 'APY',
        key: 'apy',
        dataIndex: 'apy',
        render: (value, record) => {
            return <Typography>{(convertPercent(value)/100).toFixed(2)}%</Typography>
        }
    },{
        title: 'Settlement Date',
        key: 'expiry',
        dataIndex: 'expiry',
        render: (value, record) => {
            return <Typography>{dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }
    }, {
        title: 'Target Price',
        key: 'strike',
        dataIndex: 'strike',
        render: (value, record) => {
            const group = groups[record.instrumentId];
            return <Typography>{convertW3Price(value, group, tokens)}</Typography>
        }
    }, {
        title: 'Type',
        key: 'investInBase',
        dataIndex: 'investInBase',
        render: (value, record) => {
            const group = groups[record.instrumentId];
            const base = tokens[group.base];
            const contra = tokens[group.contra];
            if (value) {
                return <Typography>Sell {base.name} for {contra.name}</Typography>
            } else {
                return <Typography>Buy {base.name} with {contra.name}</Typography>
            }
        }
    },  {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (value, record) => {
            const texts = [DCIStatus[value]]
            if (['EXERCISED', 'EXPIRED'].includes(DCIStatus[value])) {
                const px = convertW3Price(record.settlementPrice, groups[record.instrumentId], tokens);
                texts.push(`PX=${px}`);
                const group = groups[record.instrumentId];
                const token = record.investInBase ? tokens[group.base] : tokens[group.contra];
                const amount = convertTokenAmt(record.amount+record.interest, token);
                if (DCIStatus[value] === 'EXERCISED') {
                    const exerciseToken = record.investInBase ? tokens[group.contra] : tokens[group.base];
                    if (record.investInBase) {
                        texts.push(`${(amount * px).toFixed(exerciseToken.displayDecimals)} ${exerciseToken.name}`)
                    } else {
                        texts.push(`${(amount / px).toFixed(exerciseToken.displayDecimals)} ${exerciseToken.name}`)
                    }
                } else {
                    texts.push(`${amount} ${token.name}`)
                }
            }
            return <>
                {texts.map(text => (<Tag key={text}>{text}</Tag>))}
            </>
        }
    }
]